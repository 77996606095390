import React from "react"
import { Container } from "react-bootstrap"
import { Link } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import "../styles/impressum.css"

export default () => (
  <div>
    <Header />
    <Container className="text-center">
      <h3>Impressum</h3>
      <hr />
      Angaben gemäß § 5 TMG:
    </Container>
    <Container className="text-left">
      <p />
      Dachdeckerei Michael Bürgel e.K.
      <br />
      Inh. Miro Graf
      <br />
      Kruppstraße 14
      <br />
      41540 Dormagen
      <br />
      Deutschland
      <p />
      <h6>Kontakt:</h6>
      Dachdeckermeister Miro Graf
      <p />
      Telefon: +49-2133/63294
      <br />
      E-Mail: info[@]dachdeckerei-buergel.de
      <br />
      Internet: www.dachdeckerei-buergel.de
      <p />
      <h6>Registereintrag:</h6>
      Eintragung im Handelsregister
      <br />
      Handwerksrolle eingetragen am 01.02.2019
      <br />
      Registergericht: Amtsgericht Neuss
      <br />
      Registernummer: HRA 7766
      <p />
      <h6>Umsatzsteuer-ID:</h6>
      Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
      <br />
      DE 15 40 51 429
      <p />
      <h6>Aufsichtsbehörde:</h6>
      Handwerkskammer Düsseldorf
      <p />
      <h6>Angaben zur Betriebshaftpflichtversicherung:</h6>
      Name und Sitz des Versicherers: SIGNAL IDUNA Dortmund
      <br />
      Geltungsraum der Versicherung: Deutschland
      <p />
      <h6>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h6>
      Miro Graf, Kruppstr. 14, 41540 Dormagen
      <p />
      <h6>
        Haftungsausschluss (Disclaimer)
        <br />
        Haftung für Inhalte
      </h6>
      Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf
      diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis
      10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
      übermittelte oder gespeicherte fremde Informationen zu überwachen oder
      nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
      hinweisen.
      <p />
      Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen
      nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
      diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
      konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
      Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
      <p />
      <h6>Haftung für Links</h6>
      Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
      Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
      Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
      Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
      verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung
      auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
      Zeitpunkt der Verlinkung nicht erkennbar.
      <p />
      Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
      ohne konkrete Anhaltspunkte einer
      <p />
      Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen
      werden wir derartige Links umgehend entfernen. Urheberrecht
      <p />
      Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
      Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
      Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen
      des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen
      Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
      privaten, nicht kommerziellen Gebrauch gestattet.
      <p />
      Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
      werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
      Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
      Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
      entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
      wir derartige Inhalte umgehend entfernen.
      <p />
      <h6>Datenschutz</h6>
      Wir sorgen uns für die Einhaltung der gesetzlichen
      Datenschutzbestimmungen. Detaillierte Informationen hierzu enthält die
      Seite <Link to="/datenschutz">Datenschutzerklärung</Link>
    </Container>
    <Footer />
  </div>
)
